import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FiExternalLink from '@meronex/icons/fi/FiExternalLink';
import FiInstagram from '@meronex/icons/fi/FiInstagram';
import FiPhone from '@meronex/icons/fi/FiPhone';
import FiYoutube from '@meronex/icons/fi/FiYoutube';
import FiFacebook from '@meronex/icons/fi/FiFacebook';
import MapButton from "./MapButton";


export default function Detailing() {
  return (
    <Container className="content">
      <Row>
        <Col xs={12} md={6} lg={8}>
          <Row>
            <Col xs={12} sm={6} md={12} lg={6} className="mb-3">
              Беларусь, Минск<br/>
              ул. Шаранговича, 19, корп. 9
              <MapButton
                modalTitle="Мойка AutoZorgo"
                mapState={{
                  center: [53.886663, 27.457312],
                  zoom: 15
                }}
                icon="icon-wash.svg"
                hintContent="Мойка AutoZorgo"
                balloonContent="ул. Шаранговича, 19, корп. 9<br/>
                <a href='tel:+375447348214'>+375 44 734 82 14</a>"
              />
            </Col>

            <Col xs={12} sm={6} md={12} lg={6} className="mb-3">
              <strong className="d-block">Режим работы</strong>
              <span className="d-block">Пн-Пт: 9.00 - 18.00</span>
              <span style={{color: "red"}}>Сб-Вс: выходной</span>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <a href="tel:+375447348214" className="d-block btn btn-outline-primary mb-2">
            <FiPhone style={{margin: "0 .5rem .25rem 0"}}/>
            +375 44 734 82 14
          </a>
          <a href="https://autozorgo-dtl.by/" className="d-block btn btn-primary mb-2">
            <FiExternalLink style={{margin: "0 .5rem .25rem 0"}}/>
            Записаться на мойку онлайн
          </a>

          <div className="net-contacts">
            <a href="https://www.instagram.com/autozorgo_detailing/" className="instagram">
              <FiInstagram/>
            </a>
            <a href="https://www.youtube.com/channel/UCMOCCuANQhdIdTQRN6w88KQ" className="youtube">
              <FiYoutube/>
            </a>
            <a href="https://www.facebook.com/autozorgo_moyka-106799257866496" className="facebook">
              <FiFacebook/>
            </a>
          </div>
        </Col>
      </Row>
      <ul>
        <li>Трехфазная мойка</li>
        <li>Комплексы услуг под климат Беларуси</li>
        <li>Очистка лака</li>
        <li>Нанесение антидождя и керамических покрытий</li>
        <li>Химчистка</li>
      </ul>
    </Container>
  )
}