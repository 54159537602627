import React from "react";
import FiShare2 from '@meronex/icons/fi/FiShare2';
import FiX from '@meronex/icons/fi/FiX';
import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const sharedUrl = "https://start.autozorgo.by";

export default class Share extends React.Component {
  state = {shown: false}

  handleOpen = () => {
    this.setState({shown: true})
  }

  handleClose = () => {
    this.setState({shown: false})
  }

  render() {
    if (!this.state.shown) {
      return (
        <button type="button" className="btn btn-share" onClick={this.handleOpen}>
          <FiShare2/>
        </button>
      )
    }

    return (
      <div className="share-wrapper" onClick={this.handleClose}>

        <div className="share-buttons">
          <ViberShareButton url={sharedUrl}>
            <ViberIcon size={48} round/>
          </ViberShareButton>

          <TelegramShareButton url={sharedUrl}>
            <TelegramIcon size={48} round />
          </TelegramShareButton>

          <WhatsappShareButton url={sharedUrl}>
            <WhatsappIcon size={48} round/>
          </WhatsappShareButton>

          <FacebookMessengerShareButton url={sharedUrl}>
            <FacebookMessengerIcon size={48} round/>
          </FacebookMessengerShareButton>

          <EmailShareButton url={sharedUrl} subject="Детейлинг авто в Минске">
            <EmailIcon size={48} round bgStyle={{fill: '#000'}}/>
          </EmailShareButton>
        </div>

        <button type="button" className="btn btn-share">
          <FiX/>
        </button>
      </div>
    )
  }

}
