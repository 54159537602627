import React, {lazy, Suspense} from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FiMapPin from '@meronex/icons/fi/FiMapPin';

const Map = lazy(() => import("./Map"));


export default class MapButton extends React.Component {
  static propTypes = {
    modalTitle: PropTypes.string.isRequired,
    mapState: PropTypes.shape({
      center: PropTypes.arrayOf(PropTypes.number).isRequired,
      zoom: PropTypes.number.isRequired
    }).isRequired,
    hintContent: PropTypes.string.isRequired,
    balloonContent: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }
  state = {modalOpen: false};

  handleOpenModal = () => {
    this.setState({modalOpen: true});
  }

  handleCloseModal = () => {
    this.setState({modalOpen: false});
  }

  render() {
    return (
      <>
        <Button size="sm" variant="outline-primary btn-map" className="d-block mt-2" onClick={this.handleOpenModal}>
          <FiMapPin style={{margin: "0 .5rem .25rem 0"}}/>
          Посмотреть на карте
        </Button>

        <Modal show={this.state.modalOpen} onHide={this.handleCloseModal} animation={false} className="map-modal">
          <Modal.Header closeButton>
            <Modal.Title>{this.props.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Suspense fallback={""}>
              <Map
                mapState={this.props.mapState}
                hintContent={this.props.hintContent}
                balloonContent={this.props.balloonContent}
                icon={this.props.icon}
              />
            </Suspense>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}