import React from "react";
import './App.scss';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Market from "./Market";
import Detailing from "./Detailing";
import Share from "./Share";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    let onTop = false;
    document.body.onscroll = () => {
      if (this.ref.current.getBoundingClientRect().top <= 0) {
        if (!onTop) {
          onTop = true;
          this.ref.current.classList.add("top");
        }
      } else {
        if (onTop) {
          onTop = false;
          this.ref.current.classList.remove("top");
        }
      }
    }
  }

  render() {
    return (
      <Container className="app">
        <Row className="justify-content-center row-logo">
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="m-0">
            <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Логотип AutoZorgo" className="logo"/>
          </Col>
        </Row>

        <div className='main-tabs' ref={this.ref}>
          <Tabs defaultActiveKey="market" transition={false}>
            <Tab eventKey="market" title="Магазин">
              <Market/>
            </Tab>

            <Tab eventKey="detailing" title="Мойка">
              <Detailing/>
            </Tab>
          </Tabs>
        </div>

        <Share/>
      </Container>
    );
  }
}

export default App;
