import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FiExternalLink from '@meronex/icons/fi/FiExternalLink';
import FiInstagram from '@meronex/icons/fi/FiInstagram';
import FiMail from '@meronex/icons/fi/FiMail';
import FiPhone from '@meronex/icons/fi/FiPhone';
import FiFacebook from '@meronex/icons/fi/FiFacebook';
import FaVk from '@meronex/icons/fa/FaVk';
import FiYoutube from '@meronex/icons/fi/FiYoutube';
import MapButton from "./MapButton";

export default function Market() {
  return (
    <Container className="content">
      <Row>
        <Col xs={12} md={6} lg={8}>
          <Row>
            <Col xs={12} sm={6} md={12} lg={6} className="mb-3">
              Беларусь, Минск<br/>
              Бабушкина 25а
              <MapButton
                modalTitle="Магазин AutoZorgo"
                mapState={{
                  center: [53.886663, 27.457312],
                  zoom: 15
                }}
                icon="icon-shop.svg"
                hintContent="Магазин AutoZorgo"
                balloonContent="
                <strong>Магазин AutoZorgo</strong><br/>
                Минск, ул. Шаранговича, 19, корп. 9<br/>
                <a href='tel:+375298771213'>+375 29 877 12 13</a>"
              />
            </Col>

            <Col xs={12} sm={6} md={12} lg={6} className="mb-3">
              <strong className="d-block">Режим работы</strong>
              <span className="d-block">Пн-Пт: 9.00 - 18.00 (обед 13.00 - 14.00)</span>
              <span style={{color: "red"}}>Cб-Вс: выходной</span>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <a href="tel:+375298771213" className="d-block btn btn-primary mb-2">
            <FiPhone style={{margin: "0 .5rem .25rem 0"}}/>
            +375 29 877 12 13
          </a>
          <a href="https://autozorgo.by/" className="d-block btn btn-outline-primary mb-2">
            <FiExternalLink style={{margin: "0 .5rem .25rem 0"}}/>
            Перейти в интернет-магазин
          </a>

          <div className="net-contacts">
            <a href="https://www.instagram.com/autozorgo/" className="instagram">
              <FiInstagram/>
            </a>
            <a href="https://www.youtube.com/channel/UCMOCCuANQhdIdTQRN6w88KQ" className="youtube">
              <FiYoutube/>
            </a>
            <a href="https://www.facebook.com/detailing.minsk" className="facebook">
              <FiFacebook/>
            </a>
            <a href="https://vk.com/autozorgo" className="vk">
              <FaVk/>
            </a>
            <a href="mailto:autozorgo@gmail.com" title="autozorgo@gmail.com" className="email">
              <FiMail />
            </a>
          </div>
        </Col>
      </Row>

      <ul>
        <li>автомобильные воски</li>
        <li>защитные составы</li>
        <li>полироли</li>
        <li>абразивы</li>
        <li>полировальные машинки</li>
        <li>полировальные подушки</li>
        <li>аппараты для мытья под давлением</li>
        <li>принадлежности для мойки авто</li>
        <li>автошампуни</li>
        <li>полотенца из микрофибры</li>
        <li>наборы для самостоятельного ухода за автомобилем</li>
        <li>химия для автомойки</li>
        <li>и многое другое</li>
      </ul>
    </Container>
  )
}